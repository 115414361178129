import React, { useContext } from "react";
import AccordionSummary from "@mui/material/AccordionSummary";
import {
  Accordion,
  Card,
  Chip,
  Icon,
  IconButton,
  Typography,
} from "@mui/material";
import Div from "@jumbo/shared/Div";
import { history } from "app/redux/store";
import { appTheme } from "app/themes/app/theme";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import {
  useDeletePivoMutation,
  useFetchPivosQuery,
} from "../../features/pivos/pivos-slice";
import Loading from "app/components/Loading";
import { SET_ALERT } from "app/redux/modules/Alerts/constants";
import { useDispatch, useSelector } from "react-redux";
import { PivoContext } from "../../PivoProvider";

/**
 * @typedef {Object} Company
 * @property {number} id - The company's unique identifier.
 * @property {string} name - The name of the company.
 * @property {string} description - The description of the company.
 * @property {string} cnpj - The CNPJ of the company.
 * @property {string} status - The status of the company.
 * @property {?number} user_id - The user ID associated with the company (can be null).
 * @property {number} company_type_id - The type ID of the company.
 * @property {?string} logo - The logo of the company (can be null).
 * @property {string} city - The city of the company.
 * @property {string} uf - The state of the company.
 * @property {?number} product_family_id - The product family ID associated with the company (can be null).
 * @property {?number} resale_id - The resale ID associated with the company (can be null).
 * @property {string} created_at - The creation date of the company.
 * @property {string} updated_at - The last update date of the company.
 * @property {?string} deleted_at - The deletion date of the company (can be null).

 * @typedef {Object} Unit
 * @property {string} address - The address of the unit.
 * @property {number} id - The unit's unique identifier.
 * @property {number} company_id - The company ID associated with this unit.
 * @property {Company} company - The company associated with this unit.

 * @typedef {Object} Profile
 * @property {number} id - The profile's unique identifier.
 * @property {string} name - The name of the person.
 * @property {string} last_name - The last name of the person.
 * @property {string} phone - The phone number of the person.
 * @property {string} avatar - The file name of the avatar image.
 * @property {number} user_id - The user ID associated with this profile.
 * @property {string} created_at - The creation date of the profile.
 * @property {string} updated_at - The last update date of the profile.
 * @property {?string} cpf - The CPF number of the person (can be null).
 * @property {number} unit_id - The unit ID associated with this profile.

 * @typedef {Object} Client
 * @property {number} id - The client's unique identifier.
 * @property {string} username - The username of the client.
 * @property {string} email - The email address of the client.
 * @property {number} status - The status of the client.
 * @property {string} created_at - The creation date of the client account.
 * @property {string} updated_at - The last update date of the client account.
 * @property {?string} deleted_at - The deletion date of the client account (can be null).
 * @property {Profile} profile - The profile associated with the client.

 * @typedef {Object} PivoData
 * @property {number} id - The unique identifier of the data object.
 * @property {Client} client - The client associated with this data object.
 * @property {number} unit_id - The unit ID associated with this data object.
 * @property {Unit} unit - The unit associated with this data object.
 * @property {string} fiscal_number - The fiscal number of the pivo.
 * @property {string} serial_number - The serial number of the pivo.
 * @property {number} pivot_spans - The number of lances of the pivo.
 * @property {string} last_oil_change - The date of the last oil change.
 * @property {string} hourmeter - The horimetro reading of the pivo.
 * @property {boolean} need_oil_change - Whether the pivo needs an oil change.
 * @property {number} reducers_count - Contagem de redutores no pivo.
 * @param {{ item: PivoData }} props - Component props.
 */

const PivoListItens = ({ item }) => {
  const { setPivoModalOpen, setPivotEdit } = useContext(PivoContext);
  const [deletePivot, { isLoading, isFetching }] = useDeletePivoMutation();
  const dispatch = useDispatch();
  const { auth } = useSelector((state) => state);
  const deleteItem = async (id) => {
    await deletePivot(id);
    dispatch({
      type: SET_ALERT,
      payload: {
        message: "Pivo deletado com sucesso",
        variant: "success",
      },
    });
  };

  const navigate = useNavigate();
  const handlePageDetails = (id) => {
    navigate(`/pivos/detalhes/${id}/dashboard`);
  };

  if (isLoading || isFetching) return <Loading />;

  return (
    <Card sx={{ mb: 1 }} data-testid={`project-item-${item.id}`}>
      <Accordion
        expanded={false}
        // onChange={handleClick}
        square
        sx={{ borderRadius: 2 }}
      >
        <AccordionSummary
          aria-controls="panel1bh-content"
          id="panel1bh-header"
          sx={{
            px: 3,
            flex: 1,
            flexDirection: "row-reverse",
            minHeight: 90,

            "& .MuiAccordionSummary-content": {
              alignItems: "center",

              "&.Mui-expanded": {
                margin: "12px 0",
              },
            },
            ".MuiAccordionSummary-expandIconWrapper": {
              borderRadius: 1,
              border: 1,
              color: "text.secondary",
              borderColor: "divider",
              transform: "none",
              height: 28,
              width: 28,
              alignItems: "center",
              justifyContent: "center",
              mr: 1,

              "&.Mui-expanded": {
                transform: "none",
                color: "primary.main",
                borderColor: "primary.main",
              },

              "& svg": {
                fontSize: "1.25rem",
              },
            },
          }}
        >
          <Div
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              alignItems: "center",
              flex: 1,
            }}
          >
            <Div
              sx={{
                mr: { xs: 0, md: 2 },
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                border: "1px solid " + appTheme.palette.red100,
                bgcolor: appTheme.palette.neutral100,
                borderRadius: "5px",
                p: (theme) => theme.spacing(1, 2),
                px: 1,
                mb: { xs: 2, md: 0 },
              }}
            >
              <Typography variant={"h6"} color={appTheme.palette.red100} mb={0}>
                {item.id}
              </Typography>
            </Div>

            <Div
              sx={{
                flex: 1,
                flexDirection: "column",
                alignItems: { xs: "center", md: "flex-start" },
              }}
            >
              <Typography
                fontSize={"12px"}
                variant={"h6"}
                color={"text.secondary"}
                mb={0.25}
              >
                N. de série
              </Typography>
              <Typography variant={"body1"}>{item.serial_number}</Typography>
            </Div>

            <Div
              sx={{
                flex: 1,
                flexDirection: "column",
                alignItems: { xs: "center", md: "flex-start" },
              }}
            >
              <Typography
                fontSize={"12px"}
                variant={"h6"}
                color={"text.secondary"}
                mb={0.25}
              >
                Horímetro
              </Typography>
              <Typography variant={"body1"}>{item.hourmeter}</Typography>
            </Div>

            <Div
              sx={{
                flex: 1,
                flexGrow: 2,
                flexDirection: "column",
                alignItems: { xs: "center", md: "flex-start" },
              }}
            >
              <Typography
                fontSize={"12px"}
                variant={"h6"}
                color={"text.secondary"}
                mb={0.25}
              >
                Empresa
              </Typography>
              <Typography variant={"body1"}>
                {item?.unit?.company?.name}
              </Typography>
            </Div>

            <Div
              sx={{
                flex: 1,
                flexGrow: 0.5,
                marginRight: 5,
                flexDirection: "column",
                alignItems: { xs: "center", md: "flex-start" },
              }}
            >
              <Typography
                fontSize={"12px"}
                variant={"h6"}
                color={"text.secondary"}
                mb={0.25}
              >
                Lances
              </Typography>
              <Typography variant={"body1"}>{item.pivot_spans}</Typography>
            </Div>

            <Div
              sx={{
                flex: 1,
                marginRight: 5,
                alignItems: { xs: "center", md: "flex-start" },
                flexGrow: 0.1,

                flexDirection: "column",
              }}
            >
              <Typography
                fontSize={"12px"}
                variant={"h6"}
                color={"text.secondary"}
                mb={0.25}
              >
                Redutores
              </Typography>
              <Typography variant={"body1"}>{item.reducers_count}</Typography>
            </Div>

            <Div
              sx={{
                flex: 1,
                flexGrow: 2,
                flexDirection: "column",
                alignItems: { xs: "center", md: "flex-start" },
              }}
            >
              <Typography
                fontSize={"12px"}
                variant={"h6"}
                color={"text.secondary"}
                mb={0.25}
              >
                Última troca de óleo
              </Typography>
              <Typography variant={"body1"}>
                {item.last_oil_change
                  ? moment(item.last_oil_change).format("DD/MM/YYYY")
                  : "N/A"}
              </Typography>
            </Div>
            <Div
              sx={{
                flex: 1,
                flexDirection: "column",
                alignItems: { xs: "center", md: "flex-start" },
              }}
            >
              <Chip
                label={item.need_oil_change ? "Erro" : "Perfeito"}
                variant="filled"
                color={item.need_oil_change ? "error" : "success"}
              />
            </Div>
          </Div>

          <Div
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              alignItems: "center",
            }}
          >
            <Div
              sx={{
                flexDirection: "column",
                alignItems: { xs: "center", md: "flex-start" },
              }}
            >
              <Typography
                fontSize={"12px"}
                variant={"h6"}
                color={"text.secondary"}
                mb={0.25}
                ml={1.5}
              >
                Ações
              </Typography>
              {auth.user.role[0].name !== "RESALE" && (
                <IconButton onClick={() => deleteItem(item.id)}>
                  <Icon data-cy="delete" color={"error"}>
                    delete
                  </Icon>
                </IconButton>
              )}
              <IconButton
                onClick={() => {
                  setPivotEdit(item);
                  setPivoModalOpen();
                }}
              >
                <Icon data-cy="edit" color={"warning"}>
                  edit
                </Icon>
              </IconButton>
              <IconButton onClick={() => handlePageDetails(item.id)}>
                <MoreHorizIcon />
              </IconButton>
            </Div>
          </Div>
        </AccordionSummary>
      </Accordion>
    </Card>
  );
};

export default PivoListItens;
