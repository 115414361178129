import React, { useContext } from "react";
import AccordionSummary from "@mui/material/AccordionSummary";
import {
  Accordion,
  Card,
  Chip,
  Icon,
  IconButton,
  LinearProgress,
  Typography,
} from "@mui/material";
import Div from "@jumbo/shared/Div";
import { history } from "app/redux/store";
import { appTheme } from "app/themes/app/theme";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import {
  useDeletePivoMutation,
  useFetchPivosQuery,
} from "../../features/pivos/pivos-slice";
import Loading from "app/components/Loading";
import { SET_ALERT } from "app/redux/modules/Alerts/constants";
import { useDispatch, useSelector } from "react-redux";
import { PivoContext } from "../../PivoProvider";
import {
  useDeleteReducerMutation,
  useFetchReducersQuery,
} from "../../features/redutores/reducer-slice";
import { PivotSkeletons } from "../../utils/skeletons";

/**
 * @typedef {Object} ReducerData
 * @property {number} id - The unique identifier of the data object.
 * @property {string} last_oil_verification - The client associated with this data object.
 * @property {number} has_leak - The unit ID associated with this data object.
 * @property {string} last_oil_change - The unit associated with this data object.
 * @property {string} serial_number - The serial number associated with this data object.
 * @property {number} contamination - The contamination associated with this data object.
 * @property {number} viscosity - The viscosity associated with this data object.
 * @property {string} created_at - The creation date of this data object.
 * @property {string} updated_at - The last update date of this data object.
 * @property {string} pivot_id - The pivot ID associated with this data object.
 * @property {number} oil_level - The oil level associated with this data object.
 * @property {string} date_last_oil_change - The oil level associated with this data object.
 * @param {{ item: ReducerData }} props - Component props.
 */

const ReducerListItens = ({ item }) => {
  const { setOpenReducerEdit, setReducerEditItem } = useContext(PivoContext);
  const dispatch = useDispatch();
  const { auth } = useSelector((state) => state);

  const [deleteReducer, { isLoading, isFetching }] = useDeleteReducerMutation();
  const reducersList = useFetchReducersQuery();

  const deleteItem = async (id) => {
    await deleteReducer(id);
    dispatch({
      type: SET_ALERT,
      payload: {
        message: "Redutor deletado com sucesso",
        variant: "success",
      },
    });
  };

  const navigate = useNavigate();
  const handlePageDetails = (id) => {
    navigate(`/pivos/detalhes/${id}`);
  };

  if (reducersList.isFetching || reducersList.isLoading) {
    return <PivotSkeletons />;
  }

  if (isLoading || isFetching) return <Loading />;

  return (
    <Card sx={{ mb: 1 }} data-testid={`project-item-${item.id}`}>
      <Accordion
        expanded={false}
        // onChange={handleClick}
        square
        sx={{ borderRadius: 2 }}
      >
        <AccordionSummary
          aria-controls="panel1bh-content"
          id="panel1bh-header"
          sx={{
            px: 3,
            flex: 1,
            flexDirection: "row-reverse",
            minHeight: 90,

            "& .MuiAccordionSummary-content": {
              alignItems: "center",

              "&.Mui-expanded": {
                margin: "12px 0",
              },
            },
            ".MuiAccordionSummary-expandIconWrapper": {
              borderRadius: 1,
              border: 1,
              color: "text.secondary",
              borderColor: "divider",
              transform: "none",
              height: 28,
              width: 28,
              alignItems: "center",
              justifyContent: "center",
              mr: 1,

              "&.Mui-expanded": {
                transform: "none",
                color: "primary.main",
                borderColor: "primary.main",
              },

              "& svg": {
                fontSize: "1.25rem",
              },
            },
          }}
        >
          <Div
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              alignItems: "center",
              flex: 1,
              marginRight: 5,
            }}
          >
            <Div
              sx={{
                flex: 1,
                flexDirection: "column",
                alignItems: { xs: "center", md: "flex-start" },
              }}
            >
              <Typography
                fontSize={"12px"}
                variant={"h6"}
                color={"text.secondary"}
                mb={0.25}
              >
                Número de série
              </Typography>
              <Typography>{item.serial_number}</Typography>
            </Div>
            <Div
              sx={{
                flex: 1,
                flexDirection: "column",
                alignItems: { xs: "center", md: "flex-start" },
              }}
            >
              <Typography
                fontSize={"12px"}
                variant={"h6"}
                color={"text.secondary"}
                mb={0.25}
              >
                Tem óleo?
              </Typography>
              <Chip
                label={item.oil_level ? "Sim" : "Não"}
                variant="filled"
                color={item.oil_level ? "success" : "error"}
              />
            </Div>
            <Div
              sx={{
                flex: 1,
                flexDirection: "column",
                alignItems: { xs: "center", md: "flex-start" },
              }}
            >
              <Typography
                fontSize={"12px"}
                variant={"h6"}
                color={"text.secondary"}
                mb={0.25}
              >
                Tem vazamento?
              </Typography>
              <Chip
                label={item.has_leak ? "Sim" : "Não"}
                variant="filled"
                color={item.has_leak ? "error" : "success"}
              />
            </Div>
            <Div
              sx={{
                flex: 1,
                flexDirection: "column",
                alignItems: { xs: "center", md: "flex-start" },
              }}
            >
              <Typography
                fontSize={"12px"}
                variant={"h6"}
                color={"text.secondary"}
                mb={0.25}
              >
                Está viscoso?
              </Typography>
              <Chip
                label={!item.viscosity ? "Não" : "Sim"}
                variant="filled"
                color={!item.viscosity ? "error" : "success"}
              />
            </Div>

            <Div
              sx={{
                flex: 1,
                flexDirection: "column",
                alignItems: { xs: "center", md: "flex-start" },
              }}
            >
              <Typography
                fontSize={"12px"}
                variant={"h6"}
                color={"text.secondary"}
                mb={0.25}
              >
                Está contaminado?
              </Typography>
              <Chip
                label={item.contamination ? "Sim" : "Não"}
                variant="filled"
                color={item.contamination ? "error" : "success"}
              />
            </Div>
          </Div>

          <Div
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              alignItems: "center",
            }}
          >
            <Div
              sx={{
                flexDirection: "column",
                alignItems: { xs: "center", md: "flex-start" },
              }}
            >
              <Typography
                fontSize={"12px"}
                variant={"h6"}
                color={"text.secondary"}
                mb={0.25}
                ml={1.5}
              >
                Ações
              </Typography>
              {auth.user.role[0].name !== "RESALE" && (
                <IconButton onClick={() => deleteItem(item.id)}>
                  <Icon data-cy="delete" color={"error"}>
                    delete
                  </Icon>
                </IconButton>
              )}
              <IconButton
                onClick={() => {
                  setReducerEditItem(item);
                  setOpenReducerEdit();
                }}
              >
                <Icon data-cy="edit" color={"warning"}>
                  edit
                </Icon>
              </IconButton>
            </Div>
          </Div>
        </AccordionSummary>
      </Accordion>
    </Card>
  );
};

export default ReducerListItens;
