import JumboCardQuick from "@jumbo/components/JumboCardQuick/JumboCardQuick";
import JumboContent from "@jumbo/components/JumboContent/JumboContent";
import Div from "@jumbo/shared/Div/Div";
import {
  Button,
  CardHeader,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  Stack,
  Typography,
} from "@mui/material";
import OsTypeField from "./components/OsTypeField/OsTypeField";

import React, { useEffect, useMemo, useState } from "react";
import { useFormik } from "formik";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";
import { ServiceOrdersSkeleton } from "./Skeleton";
import { useNavigate } from "react-router-dom";
import { KREBSFER, osTypes, ROLE_REVENDA } from "./untils/constants";
import { formatDate } from "app/utils/appHelpers";
import { editSoValidationSchema } from "./formValidation";
import PivotField from "./components/PivotField/PivotField";
import ReducerField from "./components/ReducerField/ReducerField";
import DzPreviews from "./components/Dnd/DzPreviews";
import PreviewDataSo from "./components/PreviewDataSo/PreviewDataSo";
import { debounce } from "lodash";
import CompanySoField from "./components/CompanySoField/CompanySoField";
import UnitSoField from "./components/UnitSoField/UnitSoField";
import ClientSoField from "./components/ClientSoField/ClientSoField";
import FamilySoField from "./components/FamilySoField/FamilySoField";
import ProductsSoField from "./components/ProductsSoField/ProductsSoField";
import ModelSoField from "./components/ModelSoField/ModelSoField";
import IssueSoField from "./components/IssueSoField/IssueSoField";
import TextSoField from "./components/TextSoField/TextSoField";
import TaskTypeSoField from "./components/TaskTypeSoField/TaskTypeSoField";
import InitialDateSo from "./components/InitialDateSo/InitialDateSo";
import InitialHourSo from "./components/InitialHourSo/InitialHourSo";
import FinalDateSo from "./components/FinalDateSo/FinalDateSo";
import FinalHourSo from "./components/FinalHourSo/FinalHourSo";
import TechOriginSoField from "./components/TechOriginSoField/TechOriginSoField";
import ResellersSoField from "./components/ResellersSoField/ResellersSoField";
import TechnicianSoField from "./components/TechnicianSoField/TechnicianSoField";
import {
  useEditServiceOrderMutation,
  useShowCurrentServiceOrderQuery,
  useUploadSoFilesMutation,
} from "./features/serviceOrderFields-slice";

export default function EditSo() {
  const { auth } = useSelector((state) => state);
  const navigate = useNavigate();
  const [filesUpload] = useUploadSoFilesMutation();
  const [editSo] = useEditServiceOrderMutation();
  const { id } = useParams();
  const { data, isFetching } = useShowCurrentServiceOrderQuery({
    id: id,
  });

  const [showImagePicker, setShowImagePicker] = useState(false);
  const [images, setImages] = useState([]);
  const [showVideoPicker, setShowVideoPicker] = useState(false);
  const [videos, setVideos] = useState([]);
  const [propertyLocation, setPropertyLocation] = useState(null);

  const formik = useFormik({
    initialValues: {
      so_type: data?.so_type || "",
      reducers_ids: data?.reducers?.map((item) => item.id), //array de ids
      pivot_id: data?.pivots[0]?.id,
      company: data?.unit?.company?.id,
      property: data?.unit?.id,
      client: data?.responsible_user?.id,
      family: data?.product_model?.product?.product_family_id,
      model: data?.product_model_id,
      issue: data?.product_family_issues.map((issue) => issue.id),
      product: data?.product_model?.product_id,
      description: data?.description,
      internal_observations: data?.internal_observations,
      invoice: data?.invoice,
      serial_number: data?.serial_number,
      service_type: data?.task?.task_type_id,
      time: dayjs(data?.task?.scheduled_at).format("HH:00"),
      scheduled_at: dayjs(data?.task?.scheduled_at),
      time_end: dayjs(data?.task?.end_at).format("HH:00"),
      end: dayjs(data?.task?.end_at),
      resale: "",
      technicians_origin: "",
      responsibles: [],
    },
    enableReinitialize: true,
    validationSchema: editSoValidationSchema,
    onSubmit: async (values) => {
      // imagens & vídeos
      const files = [];
      for (let i = 0; i < images.length; i++) {
        files.push({
          file: images[i],
          description: `Imagem atendimento ${i + 1}`,
        });
      }
      for (let i = 0; i < videos.length; i++) {
        files.push({
          file: videos[i],
          description: `Video atendimento ${i + 1}`,
        });
      }

      // data da api
      const serviceData = {
        id: id,
        description: values.description,
        invoice: values.invoice,
        serial_number: values.serial_number,
        service_type: values.service_type,
        responsible_user_id: values.client,
        latitude: propertyLocation.lat,
        longitude: propertyLocation.lng,
        internal_observations: values.internal_observations,
        orders_type_id: 1,
        user_id: auth.user?.id,
        technicians_ids: values.responsibles,
        unit_id: values.property,
        product_model_id: values.model,
        product_family_issues_ids: values.issue,
        scheduled_at: formatDate(values.scheduled_at, values.time),
        end_at: formatDate(values.end, values.time_end),
      };

      if (values.so_type === osTypes.REDUCER_MAINTENANCE) {
        serviceData.reducers_ids = values.reducers_ids;
        serviceData.so_type = values.so_type;
      }

      const response = await editSo(serviceData);
      filesUpload({ id: response?.data?.id, file: files });
      navigate("/os/aberto");
    },
  });

  // Preview data
  useEffect(() => {
    setPreviewData({
      company: data?.unit?.company?.name,
      property: data?.unit?.address,
      client: `${data?.responsible_user?.profile.name} ${data?.responsible_user.profile?.last_name}`,
      family: data?.product_model?.product?.product_family?.name,
      product: data?.product_model?.product.name,
      model: data?.product_model?.name,
      issue: data?.product_family_issues.map((issue) => issue.name + ", "),
      description: data?.description,
      internal_observations: data?.internal_observations,
      invoice: data?.invoice,
      serial_number: data?.serial_number,
      service_type: data?.task.task_type.name,
      responsibles: data?.external_user_technicians.map(
        (tec) => tec.user?.profile?.name
      ),
      scheduled_at: [
        dayjs(data?.task.scheduled_at).format("DD/MM/YYYY"),
        dayjs(data?.task.scheduled_at).format("HH:00"),
      ],
      endAt: [
        dayjs(data?.task.scheduled_at).format("DD/MM/YYYY"),
        dayjs(data?.task.scheduled_at).format("HH:00"),
      ],
    });

    setPropertyLocation({
      lat: Number(data?.latitude),
      lng: Number(data?.longitude),
    });
  }, [!isFetching]);

  const [previewData, setPreviewData] = useState({
    company: "",
    property: "",
    client: "",
    family: "",
    product: "",
    model: "",
    issue: "",
    description: "",
    internal_observations: "",
    invoice: "",
    serial_number: "",
    service_type: "",
    responsibles: "",
    scheduled_at: ["", ""],
    endAt: ["", ""],
  });

  const handlePreviewData = (field, value) => {
    setPreviewData((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const [changeTechnicians, setChangeTechnicians] = useState(false);
  const [show, setShow] = useState(false);

  const handleChangeTechnicians = (e) => {
    setChangeTechnicians(e);

    if (e === false) {
      setShow(false);
      formik.setFieldValue("technicians_origin", "");
    }
  };

  const debouncedValidate = useMemo(
    () => debounce(formik.validateForm, 300),
    [formik.validateForm]
  );

  useEffect(() => {
    debouncedValidate(formik.values);
  }, [formik.values, debouncedValidate]);

  useEffect(() => {
    console.log("dae", data);
  }, [data]);

  return (
    <Div>
      <Stack>
        <Typography variant={"h2"} mb={3}>
          Editar ordem de serviço #{id}
        </Typography>
      </Stack>

      {!data || isFetching ? (
        <ServiceOrdersSkeleton />
      ) : (
        <JumboCardQuick>
          <JumboContent>
            <Stack direction="row" flex={1}>
              <form
                autoComplete="off"
                style={{ display: "flex", flex: 1 }}
                onSubmit={formik.handleSubmit}
              >
                <Div sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
                  {/* // aqui colocar o o if de role revenda */}

                  {auth.user.role[0].name !== ROLE_REVENDA && (
                    <>
                      <CardHeader title="Tipo de OS" sx={{ mb: -1, ml: -2 }} />
                      <Divider sx={{ mb: 2 }} />
                      <OsTypeField formik={formik} />
                      {formik.values.so_type ===
                        osTypes.REDUCER_MAINTENANCE && (
                        <>
                          <PivotField
                            formik={formik}
                            handlePreviewData={handlePreviewData}
                            setPropertyLocation={setPropertyLocation}
                          />
                          <ReducerField formik={formik} />
                        </>
                      )}
                    </>
                  )}
                  <CardHeader title="Cliente" sx={{ mb: -1, ml: -2 }} />

                  <Divider sx={{ mb: 2 }} />
                  <CompanySoField
                    formik={formik}
                    setPropertyLocation={setPropertyLocation}
                    handlePreviewData={handlePreviewData}
                  />
                  <UnitSoField
                    formik={formik}
                    setPropertyLocation={setPropertyLocation}
                    handlePreviewData={handlePreviewData}
                  />
                  <ClientSoField
                    formik={formik}
                    handlePreviewData={handlePreviewData}
                  />

                  {formik.values.so_type !== osTypes.REDUCER_MAINTENANCE && (
                    <>
                      <CardHeader title="Produto" sx={{ mb: -1, ml: -2 }} />
                      <Divider sx={{ mb: 2 }} />
                      <FamilySoField
                        formik={formik}
                        handlePreviewData={handlePreviewData}
                      />

                      <ProductsSoField
                        formik={formik}
                        handlePreviewData={handlePreviewData}
                      />
                      <ModelSoField
                        formik={formik}
                        handlePreviewData={handlePreviewData}
                      />
                      <IssueSoField
                        formik={formik}
                        handlePreviewData={handlePreviewData}
                      />
                    </>
                  )}

                  <CardHeader title="Serviço" sx={{ mb: -1, ml: -2 }} />
                  <Divider sx={{ mb: 2 }} />
                  <TextSoField
                    formik={formik}
                    fieldName={"description"}
                    fieldLabel={"Descrição da Ordem de Serviço"}
                    handlePreviewData={handlePreviewData}
                    rows={2}
                  />
                  <TextSoField
                    formik={formik}
                    fieldName={"internal_observations"}
                    fieldLabel={"Observações Internas"}
                    handlePreviewData={handlePreviewData}
                    rows={2}
                  />
                  <TextSoField
                    formik={formik}
                    fieldName={"invoice"}
                    fieldLabel={"Nota Fiscal"}
                    handlePreviewData={handlePreviewData}
                    rows={1}
                  />
                  <TextSoField
                    formik={formik}
                    fieldName={"serial_number"}
                    fieldLabel={"Número de Série"}
                    handlePreviewData={handlePreviewData}
                    rows={1}
                  />

                  <TaskTypeSoField
                    formik={formik}
                    handlePreviewData={handlePreviewData}
                  />

                  <InitialDateSo
                    formik={formik}
                    handlePreviewData={setPreviewData}
                  />
                  <InitialHourSo
                    formik={formik}
                    handlePreviewData={setPreviewData}
                  />
                  <FinalDateSo
                    formik={formik}
                    handlePreviewData={setPreviewData}
                  />
                  <FinalHourSo
                    formik={formik}
                    handlePreviewData={setPreviewData}
                    previewData={previewData}
                  />

                  <FormControl>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="changeTechnicians"
                          checked={changeTechnicians}
                          onChange={(e) =>
                            handleChangeTechnicians(e.target.checked)
                          }
                        />
                      }
                      label="Alterar técnicos responsáveis?"
                    />
                  </FormControl>

                  {changeTechnicians && (
                    <>
                      {auth.user && auth.user.role[0].name !== ROLE_REVENDA && (
                        <TechOriginSoField
                          formik={formik}
                          handlePreviewData={handlePreviewData}
                        />
                      )}
                      {auth.user &&
                        auth.user.role[0].name !== ROLE_REVENDA &&
                        formik.values.technicians_origin === ROLE_REVENDA && (
                          <ResellersSoField
                            formik={formik}
                            handlePreviewData={handlePreviewData}
                          />
                        )}

                      {(formik.values.resale ||
                        formik.values.technicians_origin === KREBSFER ||
                        auth.user.role[0].name === ROLE_REVENDA) && (
                        <TechnicianSoField
                          handlePreviewData={handlePreviewData}
                          formik={formik}
                        />
                      )}
                    </>
                  )}
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={showImagePicker}
                          onChange={(e) => setShowImagePicker(e.target.checked)}
                          inputProps={{ "aria-label": "controlled" }}
                        />
                      }
                      label="Deseja inserir imagens?"
                    />
                  </FormGroup>

                  {showImagePicker && (
                    <FormControl>
                      <InputLabel>Imagens do Equipamento</InputLabel>
                      <DzPreviews selectFiles={setImages} image />
                    </FormControl>
                  )}

                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={showVideoPicker}
                          onChange={(e) => setShowVideoPicker(e.target.checked)}
                          inputProps={{ "aria-label": "controlled" }}
                        />
                      }
                      label="Deseja inserir vídeos?"
                    />
                  </FormGroup>

                  {showVideoPicker && (
                    <FormControl>
                      <InputLabel>Vídeos do Equipamento</InputLabel>
                      <DzPreviews selectFiles={setVideos} video />
                    </FormControl>
                  )}

                  <Button
                    data-testid="btn-submit"
                    variant="contained"
                    color="error"
                    type="submit"
                    sx={{
                      mt: 5,
                    }}
                  >
                    Salvar
                  </Button>
                </Div>
              </form>

              <PreviewDataSo
                formik={formik}
                propertyLocation={propertyLocation}
                previewData={previewData}
              />
            </Stack>
          </JumboContent>
        </JumboCardQuick>
      )}
    </Div>
  );
}
