import {
  Autocomplete,
  CircularProgress,
  FormControl,
  TextField,
} from "@mui/material";
import React, { useEffect } from "react";
import { useFetchPivotsQuery } from "../../features/serviceOrderFields-slice";
import { FieldSkeleton } from "app/components/CustomInputs/Skeleton";
import { useDispatch } from "react-redux";
import { setPivotId, setSelectedReducers } from "../../features/so-slice";

function PivotField({
  formik,
  handlePreviewData,
  setPropertyLocation,
  ...props
}) {
  const { data, isFetching, isLoading, refetch } = useFetchPivotsQuery();
  const dispatch = useDispatch();
  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    console.log("33", data);
  }, [data]);

  if ((isLoading, isFetching)) return <FieldSkeleton />;
  return (
    <FormControl sx={{ mb: 1 }}>
      <Autocomplete
        {...props}
        filterSelectedOptions={true}
        options={data?.data || []}
        loading={isLoading || isFetching}
        fullWidth
        InputProps={{
          autoComplete: "off",
        }}
        onChange={(_, value) => {
          formik.setFieldValue("pivot_id", value !== null ? value.id : "");
          formik.setFieldValue("reducers_ids", []);
          dispatch(setSelectedReducers([]));

          formik.setFieldValue("company", value.unit.company.id);
          handlePreviewData(
            "company",
            value !== null ? value.unit.company.name : undefined
          );
          formik.setFieldValue("property", value.unit.id);
          handlePreviewData(
            "property",
            value !== null ? value.unit.address : undefined
          );
          setPropertyLocation(
            value !== null
              ? {
                  lat: Number(value.unit.latitude),
                  lng: Number(value.unit.longitude),
                }
              : null
          );
        }}
        value={
          data?.data?.find((option) => option.id === formik.values.pivot_id) ||
          null
        }
        renderOption={(props, option) => (
          <li {...props} key={option.id}>
            {option.serial_number}
          </li>
        )}
        getOptionLabel={(option) => option.serial_number || ""}
        renderInput={(params) => (
          <TextField
            className="p-0"
            {...params}
            fullWidth
            id={"pivot_id"}
            name={"pivot_id"}
            label={"Pivô"}
            InputProps={{
              ...params.InputProps,

              endAdornment: (
                <React.Fragment>
                  {isLoading || isFetching ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
    </FormControl>
  );
}

export default PivotField;
