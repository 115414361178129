import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const serviceOrderFieldsSlice = createApi({
  reducerPath: "serviceOrderFieldsSlice",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    prepareHeaders: (headers, { getState }) => {
      const { auth } = getState();
      if (!auth.token) return headers;
      headers.set("authorization", `Bearer ${auth.token}`);
      return headers;
    },
  }),

  endpoints(builder) {
    return {
      fetchPivots: builder.query({
        query() {
          return "pivots/?noPaginate=true";
        },
        providesTags: ["Pivots"],
        invalidatesTags: ["Reducers"],
      }),
      fetchReducers: builder.query({
        query(pivot_id) {
          return `pivots/reducers/?pivot_id=${pivot_id}&noPaginate=true`;
        },
        providesTags: ["Reducers"],
      }),
      fetchCompanies: builder.query({
        query() {
          return `companies?orderBy=id,desc&status=1&page=1&s=&noPaginate=true&city=&uf=&product_family_id=&company_type_id=1`;
        },
      }),
      fetchUnits: builder.query({
        query(company_id) {
          return `units?orderBy=name,desc&status=1&company_id=${company_id}&noPaginate=true`;
        },
      }),
      fetchClients: builder.query({
        query(company_id) {
          return `users?orderBy=id,desc&status=1&page=1&s=&noPaginate=true&role_id=2&unit_id=${company_id}`;
        },
      }),
      fetchFamilies: builder.query({
        query() {
          return `product-families?orderBy=id,desc&status=1&page=1&s=&noPaginate=true`;
        },
      }),
      fetchProducts: builder.query({
        query(family_id) {
          return `products?orderBy=id,desc&status=1&page=1&s=&noPaginate=true&product_family_id=${family_id}`;
        },
      }),
      fetchModels: builder.query({
        query(product_id) {
          return `product-models?orderBy=id,desc&status=1&page=1&s=&noPaginate=true&product_id=${product_id}`;
        },
      }),
      fetchIssues: builder.query({
        query(model_id) {
          return `product-families/issues/?orderBy=id,desc&status=1&page=1&s=&noPaginate=true&product_family_id=${model_id}`;
        },
      }),
      fetchTaskTypes: builder.query({
        query() {
          return `task-types?orderBy=id,desc&status=1&page=1&s=&noPaginate=true`;
        },
      }),
      fetchResellers: builder.query({
        query() {
          return `companies?orderBy=id,desc&status=1&page=1&noPaginate=false&city=&uf=&product_family_id=&company_type_id=2`;
        },
      }),
      fetchTechnician: builder.query({
        query: (arg) => {
          const { company_id, start, end } = arg;
          console.log("arg", arg);
          return {
            url: `users/technicians/without-scheduled-tasks`,
            params: { company_id, start, end },
          };
        },
      }),
      newServiceOrder: builder.mutation({
        query(body) {
          return {
            url: "/service-orders/",
            method: "POST",
            body,
          };
        },
      }),
      showCurrentServiceOrder: builder.query({
        query(values) {
          return `/service-orders/${values.id}`;
        },
      }),
      editServiceOrder: builder.mutation({
        query(values) {
          return {
            url: `/service-orders/${values.id}`,
            method: "PUT",
            body: values,
          };
        },
      }),
      uploadSoFiles: builder.mutation({
        query({ id, file }) {
          const formData = new FormData();
          file.map((arq) => {
            formData.append("files", arq.file);
            formData.append("description", arq.description);
          });

          formData.append("service_order_id", id);
          formData.append("stage", "OPEN");

          return {
            url: `attachments`,
            method: "POST",
            body: formData,
            formData: true,
          };
        },
      }),
    };
  },
});

export const {
  useFetchPivotsQuery,
  useFetchReducersQuery,
  useFetchCompaniesQuery,
  useLazyFetchUnitsQuery,
  useLazyFetchClientsQuery,
  useFetchFamiliesQuery,
  useLazyFetchProductsQuery,
  useLazyFetchModelsQuery,
  useLazyFetchIssuesQuery,
  useFetchTaskTypesQuery,
  useFetchResellersQuery,
  useFetchTechnicianQuery,
  useNewServiceOrderMutation,
  useUploadSoFilesMutation,
  useEditServiceOrderMutation,
  useShowCurrentServiceOrderQuery,
} = serviceOrderFieldsSlice;
