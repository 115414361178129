import * as Yup from "yup";
import dayjs from "dayjs";
import { osTypes, ROLE_REVENDA } from "./untils/constants";

export const newSoValidationSchema = Yup.object().shape({
  company: Yup.number().required("Campo obrigatório"),
  property: Yup.number().required("Campo obrigatório"),
  client: Yup.number().required("Campo obrigatório"),
  family: Yup.number().when("so_type", {
    is: osTypes.STANDARD,
    then: Yup.number().required("Campo obrigatório"),
  }),
  product: Yup.number().when("so_type", {
    is: osTypes.STANDARD,
    then: Yup.number().required("Campo obrigatório"),
  }),
  model: Yup.number().when("so_type", {
    is: osTypes.STANDARD,
    then: Yup.number().required("Campo obrigatório"),
  }),
  issue: Yup.array().when("so_type", {
    is: osTypes.STANDARD,
    then: Yup.array()
      .min(1, "Selecione ao menos 1 item")
      .required("Obrigatório"),
  }),

  description: Yup.string().required("Campo obrigatório"),
  internal_observations: Yup.string().optional(),
  invoice: Yup.string().optional(),
  serial_number: Yup.string().optional(),
  service_type: Yup.string().required("Campo obrigatório"),
  scheduled_at: Yup.date()
    .min(dayjs().startOf("day").subtract(1, "s"), "Data não permitida")
    .nullable()
    .required("Campo obrigatório")
    .typeError("Data inválida"),
  time: Yup.string().required("Campo obrigatório"),
  end: Yup.date()
    .min(dayjs().startOf("day").subtract(1, "s"), "Data não permitida")
    .nullable()
    .required("Campo obrigatório"),
  time_end: Yup.string().required("Campo obrigatório"),
  technicians_origin: Yup.string().required("Campo obrigatório"),
  resale: Yup.string()
    .ensure()
    .when("technicians_origin", {
      is: ROLE_REVENDA,
      then: Yup.string().required("Campo obrigatório"),
    }),
  responsibles: Yup.array().min(1, "Campo obrigatório"),
});

export const editSoValidationSchema = Yup.object().shape({
  company: Yup.string().required("Campo obrigatório"),
  property: Yup.string().required("Campo obrigatório"),
  client: Yup.string().required("Campo obrigatório"),
  family: Yup.number().when("so_type", {
    is: osTypes.STANDARD,
    then: Yup.number().required("Campo obrigatório"),
  }),
  product: Yup.number().when("so_type", {
    is: osTypes.STANDARD,
    then: Yup.number().required("Campo obrigatório"),
  }),
  model: Yup.number().when("so_type", {
    is: osTypes.STANDARD,
    then: Yup.number().required("Campo obrigatório"),
  }),
  issue: Yup.array().when("so_type", {
    is: osTypes.STANDARD,
    then: Yup.array()
      .min(1, "Selecione ao menos 1 item")
      .required("Obrigatório"),
  }),
  description: Yup.string().required("Campo obrigatório"),
  internal_observations: Yup.string().optional(),
  invoice: Yup.string().optional(),
  serial_number: Yup.string().optional(),
  service_type: Yup.string().required("Campo obrigatório"),
  scheduled_at: Yup.date()
    .nullable()
    .required("Campo obrigatório")
    .typeError("Data inválida"),
  time: Yup.string().required("Campo obrigatório"),
  end: Yup.date()
    .min(Yup.ref("scheduled_at"), "Data não permitida")
    .nullable()
    .required("Campo obrigatório")
    .typeError("Data inválida"),
  time_end: Yup.string().required("Campo obrigatório"),
  technicians_origin: Yup.string().optional(),
  resale: Yup.string().optional(),
  responsibles: Yup.array().optional(),
  // technicians_origin: Yup.string().required("Campo obrigatório"),
  // resale: Yup.string()
  //   .ensure()
  //   .when("technicians_origin", {
  //     is: ROLE_REVENDA,
  //     then: Yup.string().required("Campo obrigatório"),
  //   }),
  // responsibles: Yup.array().min(1, "Campo obrigatório"),
});
