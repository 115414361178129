import React, { useEffect, useRef } from "react";
import LifeManagerItens from "./LifeManagerItens";
import {
  useInitialListQuery,
  useListFilesCategoriesQuery,
  useListFilesQuery,
} from "../features/fileManager-slice";
import LifeManagerItensCategories from "./LifeManagerItensCategories";
import { Pagination, Skeleton } from "@mui/material";
import useFileManagerContext from "../hooks/useFileManagerContext";

function FileManagerListComponent() {
  const [pages, setPages] = React.useState(1);
  const { searchKeyWord } = useFileManagerContext();
  const {
    data: files,
    isLoading,
    isFetching,
  } = useListFilesQuery({ values: { page: pages, search: searchKeyWord } });

  const {
    data: categories,
    isLoading: isLoadgindCat,
    isFetching: isFetchingCat,
  } = useInitialListQuery();

  if (isLoading || isFetching || isLoadgindCat || isFetchingCat) {
    return (
      <Skeleton
        variant="rectangular"
        className="rounded-lg w-full"
        height={800}
        animation="wave"
      />
    );
  }
  return (
    <div className="grid grid-cols-1">
      <div className="bg-white p-5 rounded-lg shadow-md grid grid-cols-12">
        <div className="col-span-12">
          <div className="flex flex-row items-center">
            <h3 className="text-lg whitespace-nowrap">Pastas</h3>
            <div className="ml-3 h-[0.5px] bg-gray-200 flex-grow" />
          </div>
        </div>
        {categories?.length === 0 && (
          <div className="col-span-12">
            <div className="flex flex-row items-center p-5">
              <h3 className="text-sm text-gray-300 italic whitespace-nowrap">
                Nenhuma pasta encontrada
              </h3>
            </div>
          </div>
        )}
        {categories?.map((file, index) => (
          <LifeManagerItensCategories key={index} item={file} />
        ))}
        <div className="col-span-12">
          <div className="flex flex-row items-center">
            <h3 className="text-lg whitespace-nowrap">Todos os documentos</h3>
            <div className="ml-3 h-[0.5px] bg-gray-200 flex-grow" />
          </div>
        </div>
        {files?.data.length === 0 && (
          <div className="col-span-12">
            <div className="flex flex-row items-center p-5">
              <h3 className="text-sm text-gray-300 italic whitespace-nowrap">
                Nenhum arquivo encontrado
              </h3>
            </div>
          </div>
        )}
        {files?.data.map((file, index) => (
          <LifeManagerItens id={file.id} key={file.id} item={file} />
        ))}
        <div className="col-span-12 py-4">
          <Pagination
            count={files.meta.last_page}
            page={pages}
            onChange={(_, e) => setPages(e)}
            variant="outlined"
            shape="rounded"
          />
        </div>
      </div>
    </div>
  );
}

export default FileManagerListComponent;
