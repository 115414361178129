import {
  Box,
  Button,
  Card,
  Divider,
  FormControl,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Slider,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { PivoContext } from "../../PivoProvider";
import Div from "@jumbo/shared/Div";
import { CloseOutlined } from "@mui/icons-material";
import { useFormik } from "formik";
import { reducerValidationSchema } from "../../utils/formValidation";

import { useDispatch, useSelector } from "react-redux";
import { SET_ALERT } from "app/redux/modules/Alerts/constants";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { sliderColor } from "../../utils/sliderColor";
import {
  useCreateReducerMutation,
  useEditReducerMutation,
} from "../../features/redutores/reducer-slice";
import { useParams } from "react-router-dom";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";
import _ from "lodash";
import ReducerSelectInput from "./ReducerSelectInput";

function ReducerModal() {
  const { id } = useParams();
  const { openReducerEdit, setCloseModal, reducerEditItem } =
    useContext(PivoContext);
  const dispatch = useDispatch();
  const item = reducerEditItem;
  const { auth } = useSelector((state) => state);
  const [createReducer] = useCreateReducerMutation();
  const [editReducer] = useEditReducerMutation();
  const [submit, setSubmit] = useState(false);
  useEffect(() => {
    if (item) {
      formik.setValues({
        serial_number: item.serial_number,
        oil_level: item.oil_level,
        has_leak: item.has_leak,
        last_oil_verification: item.last_oil_verification
          ? dayjs(item?.last_oil_verification)
          : undefined,
        date_last_oil_change: item.date_last_oil_change
          ? dayjs(item?.date_last_oil_change)
          : undefined,
        viscosity: item.viscosity,
        contamination: item.contamination,
        pivot_id: id,
      });
    }
  }, [item]);

  const debounceLoading = _.debounce((value) => {
    setSubmit(value);
  }, 1000);

  const debouncedSubmit = _.debounce((values) => {
    if (item) {
      values = { ...values, id: item.id };
      editReducer(values)
        .unwrap()
        .then((res) => {
          dispatch({
            type: SET_ALERT,
            payload: {
              message: "Redutor editado com sucesso",
              variant: "success",
            },
          });
          setCloseModal();
          formik.resetForm();
        })
        .catch((err) => {
          dispatch({
            type: SET_ALERT,
            payload: { message: "Erro ao editar Redutor", variant: "error" },
          });
        });
      debounceLoading(false);
      return;
    }
    createReducer(values)
      .unwrap()
      .then((res) => {
        dispatch({
          type: SET_ALERT,
          payload: { message: "Pivo criado com sucesso", variant: "success" },
        });

        setCloseModal();
        formik.resetForm();
      })
      .catch((err) => {
        dispatch({
          type: SET_ALERT,
          payload: { message: "Erro ao criar pivo", variant: "error" },
        });
      });
    debounceLoading(false);
  }, 300);

  const formik = useFormik({
    initialValues: {
      serial_number: "",
      oil_level: 1,
      has_leak: 0,
      last_oil_verification: undefined,
      date_last_oil_change: undefined,
      viscosity: 1,
      contamination: 0,
      pivot_id: id,
    },
    onSubmit: (values) => {
      setSubmit(true);
      debouncedSubmit(values);
    },
    validationSchema: reducerValidationSchema,
    validateOnMount: true,
    validateOnChange: false,
  });

  return (
    <Modal
      onClose={() => {
        formik.resetForm();

        setCloseModal();
      }}
      open={openReducerEdit}
    >
      <Card style={$modalBox} sx={{ p: 2, width: 400, height: 400 }}>
        <Div className="flex flex-row justify-between items-center mb-2 scroll-auto">
          <Typography id="transition-modal-title" variant="h4" component="h2">
            {item
              ? `Editar Redutor - #${item.serial_number}`
              : `Adicionar novo Redutor`}
          </Typography>

          <Button
            color="error"
            onClick={() => {
              formik.resetForm();

              setCloseModal();
            }}
          >
            <CloseOutlined />
          </Button>
        </Div>
        <Divider sx={{ mb: 2 }} />

        <Stack flexDirection="row">
          <form
            data-cy="form"
            style={{ display: "flex", flex: 1 }}
            onSubmit={formik.handleSubmit}
          >
            <Div
              spacing={2}
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 1,
                boxSizing: "border-box",
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <TextField
                      fullWidth
                      label="Número de série"
                      name="serial_number"
                      value={formik.values.serial_number}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.serial_number &&
                        Boolean(formik.errors.serial_number)
                      }
                      helperText={
                        formik.touched.serial_number &&
                        formik.errors.serial_number
                      }
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        data-testid={"input-date"}
                        id="last_oil_verification"
                        name="last_oil_verification"
                        label="Última verificação do óleo"
                        format="DD/MM/YYYY"
                        disabled={auth.user.role[0].name === "RESALE"}
                        onAccept={(newValue) => {
                          if (newValue) {
                            formik.setFieldValue(
                              "last_oil_verification",
                              newValue,
                              true
                            );
                          }
                        }}
                        onChange={(newValue) => {
                          if (
                            newValue &&
                            newValue.format("DD/MM/YYYY") !== "Invalid Date"
                          ) {
                            formik.setFieldValue(
                              "last_oil_verification",
                              newValue,
                              true
                            );
                          }
                        }}
                        value={formik.values.last_oil_verification}
                        slotProps={{
                          textField: {
                            variant: "outlined",
                            onBlur: () => {
                              if (formik.values["last_oil_verification"]) {
                                const today = dayjs().startOf("day");
                                const date =
                                  formik.values["last_oil_verification"];
                                const diff = date.diff(today, "d");

                                if (diff < 0) {
                                  formik.setFieldValue(
                                    "last_oil_verification",
                                    today
                                  );
                                }
                              }
                            },
                          },
                        }}
                      />
                    </LocalizationProvider>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        data-testid={"input-date"}
                        id="date_last_oil_change"
                        name="date_last_oil_change"
                        label="Última troca de óleo"
                        format="DD/MM/YYYY"
                        disabled={auth.user.role[0].name === "RESALE"}
                        onAccept={(newValue) => {
                          if (newValue) {
                            formik.setFieldValue(
                              "date_last_oil_change",
                              newValue,
                              true
                            );
                          }
                        }}
                        onChange={(newValue) => {
                          if (
                            newValue &&
                            newValue.format("DD/MM/YYYY") !== "Invalid Date"
                          ) {
                            formik.setFieldValue(
                              "date_last_oil_change",
                              newValue,
                              true
                            );
                          }
                        }}
                        value={formik.values.date_last_oil_change}
                        slotProps={{
                          textField: {
                            variant: "outlined",
                            onBlur: () => {
                              if (formik.values["date_last_oil_change"]) {
                                const today = dayjs().startOf("day");
                                const date =
                                  formik.values["date_last_oil_change"];
                                const diff = date.diff(today, "d");

                                if (diff < 0) {
                                  formik.setFieldValue(
                                    "date_last_oil_change",
                                    today
                                  );
                                }
                              }
                            },
                          },
                        }}
                      />
                    </LocalizationProvider>
                  </FormControl>
                </Grid>
                <Grid item xs={3}>
                  <ReducerSelectInput
                    labelId="oil_level"
                    id="oil_level"
                    name="oil_level"
                    value={formik.values.oil_level}
                    label="Tem óleo?"
                    disabled={auth.user.role[0].name === "RESALE"}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.oil_level &&
                      Boolean(formik.errors.oil_level)
                    }
                    helperText={
                      formik.touched.oil_level && formik.errors.oil_level
                    }
                  />
                </Grid>
                <Grid item xs={3}>
                  <ReducerSelectInput
                    labelId="has_leak"
                    id="has_leak"
                    name="has_leak"
                    value={formik.values.has_leak}
                    disabled={auth.user.role[0].name === "RESALE"}
                    label="Tem vazamento?"
                    onChange={formik.handleChange}
                    error={
                      formik.touched.has_leak && Boolean(formik.errors.has_leak)
                    }
                    helperText={
                      formik.touched.has_leak && formik.errors.has_leak
                    }
                  />
                </Grid>
                <Grid item xs={3}>
                  <ReducerSelectInput
                    labelId="viscosity"
                    id="viscosity"
                    name="viscosity"
                    value={formik.values.viscosity}
                    disabled={auth.user.role[0].name === "RESALE"}
                    label="Está viscoso?"
                    onChange={formik.handleChange}
                    error={
                      formik.touched.viscosity &&
                      Boolean(formik.errors.viscosity)
                    }
                    helperText={
                      formik.touched.viscosity && formik.errors.viscosity
                    }
                  />
                </Grid>
                <Grid item xs={3}>
                  <ReducerSelectInput
                    labelId="contamination"
                    id="contamination"
                    name="contamination"
                    value={formik.values.contamination}
                    disabled={auth.user.role[0].name === "RESALE"}
                    label="Está contaminado?"
                    onChange={formik.handleChange}
                    error={
                      formik.touched.contamination &&
                      Boolean(formik.errors.contamination)
                    }
                    helperText={
                      formik.touched.contamination &&
                      formik.errors.contamination
                    }
                  />
                </Grid>
              </Grid>

              <Divider sx={{ mb: 2, mt: 2 }} />
              <Stack flexDirection="row" justifyContent={"flex-end"}>
                <Button
                  variant="contained"
                  color="error"
                  sx={{ mr: 2 }}
                  onClick={() => {
                    formik.resetForm();
                    setCloseModal();
                  }}
                >
                  Cancelar
                </Button>
                <LoadingButton
                  loading={submit}
                  loadingPosition="end"
                  endIcon={<SaveIcon />}
                  variant="contained"
                  color="success"
                  type="submit"
                >
                  Salvar
                </LoadingButton>
              </Stack>
            </Div>
          </form>
        </Stack>
      </Card>
    </Modal>
  );
}

const $modalBox = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60%",
  bgcolor: "background.paper",
  borderRadius: 5,
  boxShadow: 24,
  p: 4,
  height: "auto",
};
export default ReducerModal;
